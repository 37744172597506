export function getColorFromFullFen(fen: string): string {
  const space_index = fen.indexOf(" ");
  let result = "";
  const abbreviation = fen.slice(space_index + 1, space_index + 2);
  if (abbreviation === "w") result = "white";
  else result = "black";

  return result;
}

export function changeCountToFakeCount(fen: string): string {
  return fen.split(" ").slice(0, -2).join(" ") + " 1 1";
}

export function fenWithoutMoveCounts(fen: string): string {
  return fen.split(" ").slice(0, -2).join(" ");
}

export function removeEnPassant(fenWithNoMoveCount: string): string {
  return fenWithNoMoveCount.split(" ").slice(0, -1).join(" ");
}

export function getMoveNumber(fen: string): number {
  const space_index = fen.lastIndexOf(" ");
  let result = fen.slice(space_index + 1);

  return parseInt(result);
}

export function getTotalGames(explorerGames: any): number {
  let result = 0;
  result = explorerGames.white + explorerGames.black + explorerGames.draws;
  return result;
}

export function getServerURL(): string {
  const fullAPIUrl = window.location.href;
  const firstColonIndex = fullAPIUrl.indexOf(":");
  console.log(firstColonIndex);
  const secondColonIndex = fullAPIUrl.indexOf(":", firstColonIndex + 1);
  console.log(secondColonIndex);
  if (secondColonIndex > 0) {
    return fullAPIUrl.slice(0, secondColonIndex);
  } else {
    return fullAPIUrl.slice(0, -1);
  }
}

export const percentFormatter = new Intl.NumberFormat("default", {
  style: "percent",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
  minimumIntegerDigits: 2,
});
