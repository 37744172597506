import readNDJSONStream from "ndjson-readablestream";
import axios from "axios";

export async function getStudies(username: string): Promise<any[]> {
  const studies: any[] = [];
  const headers = {
    Authorization: "Bearer lip_C7ogCYOHkYq74NpmO4Fl",
    Accept: "application/x-ndjson",
  };

  const response = await fetch(`https://lichess.org/api/study/by/` + username, {
    headers,
    cache: "no-cache",
  });
  if (response.body !== null) {
    for await (const event of readNDJSONStream(response.body)) {
      studies.push(event);
    }
  }

  console.log("studies", studies);
  return studies;
}

export async function getPublicStudies(username: string): Promise<any[]> {
  const studies: any[] = [];

  const response = await fetch(`https://lichess.org/api/study/by/` + username, {
    cache: "no-cache",
  });
  if (response.body !== null) {
    for await (const event of readNDJSONStream(response.body)) {
      studies.push(event);
    }
  }

  console.log("studies", studies);
  return studies;
}

export async function getPublicStudy(id: string): Promise<string> {
  try {
    const headers = {
      Authorization: "Bearer lip_C7ogCYOHkYq74NpmO4Fl",
    };
    const response = await fetch(
      `https://lichess.org/api/study/` + id + `.pgn`,
      { cache: "no-cache", headers }
    );
    if (response.body !== null) {
      //const reader = response.body.getReader();
      let pgn = await new Response(response.body).text();
      return pgn;
    } else {
      return "";
    }
  } catch (e) {
    console.log("Get Study Error:", e);
    return e;
  }
}
export async function getStudy(id: string): Promise<void> {
  try {
    const headers = {
      Authorization: "Bearer lip_C7ogCYOHkYq74NpmO4Fl",
    };
    const response = await fetch(
      `https://lichess.org/api/study/` + id + `.pgn`,
      {
        headers,
        cache: "no-cache",
      }
    );
    if (response.body !== null) {
      //const reader = response.body.getReader();
      let pgn = await new Response(response.body).text();
      console.log(pgn);
    }
  } catch (e) {
    console.log("Get Study Error:", e);
    return e;
  }
}
