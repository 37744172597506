export async function getExplorerGames(
  speeds: string[],
  ratings: string[],
  fen: string
): Promise<Object> {
  try {
    const headers = {
      Authorization: "Bearer lip_C7ogCYOHkYq74NpmO4Fl",
    };
    const speed_string = speeds.join(",");
    const rating_string = ratings.join(",");
    const response = await fetch(
      `https://explorer.lichess.ovh/lichess?variant=standard&speeds=` +
        speed_string +
        `&ratings=` +
        rating_string +
        `&fen=` +
        fen,
      {
        cache: "no-cache",
      }
    );
    if (response.body !== null) {
      //const reader = response.body.getReader();
      let games = await new Response(response.body).json();

      return games;
    } else {
      return "";
    }
  } catch (e) {
    console.log("Get Explorer Games Error:", e);
    return e;
  }
}
