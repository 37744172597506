export function getExplorerSettings(ratings: {}, speeds: {}) {
  const currentRatings = [];
  for (const rating in ratings) {
    if (ratings[rating as keyof typeof ratings]) {
      currentRatings.push(rating);
    }
  }
  const currentSpeeds = [];
  for (const speed in speeds) {
    if (speeds[speed as keyof typeof speeds]) {
      currentSpeeds.push(speed);
    }
  }
  return {
    ratings: currentRatings.toString(),
    speeds: currentSpeeds.toString(),
    ratingsArray: currentRatings,
    speedsArray: currentSpeeds,
  };
}
