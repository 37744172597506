/** @jsxImportSource @emotion/react */
import React, { useState, useRef } from "react";
import axios from "axios";
import { css } from "@emotion/react";
import {
  getPublicStudies,
  getPublicStudy,
} from "../lichess_chess_dot_com/get_studies";
import {
  getPublicLichessGames,
  getChesssComGames,
} from "../lichess_chess_dot_com/get_games";
import { getExplorerSettings } from "./get_explorer_settings";
import { makePgn } from "chessops/pgn";
import { getExplorerGames } from "../lichess_chess_dot_com/get_explorer_games";
import { getDBStats, updateDBStats } from "../database/lichess_stats";
import { generateCoverageString } from "./generate_coverage_string";
import { Chess } from "cm-chess";
import {
  changeCountToFakeCount,
  getColorFromFullFen,
  getServerURL,
  getTotalGames,
  removeEnPassant,
} from "../utilities/general";
import { getBook } from "./get_book";
const _ = require("lodash");
axios.defaults.withCredentials = true;

export const ReviewOpening = () => {
  const initialFile: any = "";
  const intialResults: any = "";
  const initialSelectedStudy: any = "";
  const initialOpeningStudyByID: any = "";
  const initialGameFileByStudyID: any = "";
  const initialStudies: any[] = [];
  const initialSpeeds = {
    ultraBullet: false,
    bullet: false,
    blitz: true,
    rapid: true,
    classical: true,
    correspondence: false,
  };

  const [speeds, setSpeeds] = useState(initialSpeeds);
  const initialRatings = {
    "400": false,
    "1000": false,
    "1200": false,
    "1400": false,
    "1600": false,
    "1800": true,
    "2000": true,
    "2200": true,
    "2500": true,
  };
  const [ratings, setRatings] = useState(initialRatings);
  const initialExcludeMoveIndicators = {
    blunder: { value: false, symbol: "??" },
    mistake: { value: false, symbol: "?" },
    speculative: { value: false, symbol: "!?" },
    dubious: { value: false, symbol: "?!" },
  };
  const [openingFile, setOpeningFile] = useState(initialFile);
  const [lichessOpeningUsername, setLichessOpeningUsername] = useState("");
  const [gameUsername, setGameUsername] = useState("");
  const [gameFile, setGameFile] = useState(initialFile);
  const [gamePGN, setGamePGN] = useState("");
  const [results, setResults] = useState(intialResults);
  const [status, setStatus] = useState("");
  const [processing, setProcessing] = useState(false);
  const [processingDots, setProcessingDots] = useState("");
  const [openingStudyID, setOpeningStudyID] = useState("");
  const [gameFileStudyID, setGameFileStudyID] = useState("");

  const gameFileInputRef = useRef<HTMLInputElement>(null);
  const [openingStudyByID, setOpeningStudyByID] = useState(
    initialOpeningStudyByID
  );
  const [lichessStudies, setlichessStudies] = useState(initialStudies);
  const [selectedLichessStudy, setSelectedLichessStudy] =
    useState(initialSelectedStudy);
  const initialGames: any[] = [];
  const [retrievedGames, setRetrievedGames] = useState(initialGames);
  const initialSelectedGame: any = "";
  const [selectedGameIndex, setSelectedGameIndex] =
    useState(initialSelectedGame);
  const [selectedColor, setSelectedColor] = useState("white");
  const [excludeMoveIndicators, setExcludeMoveIndicators] = useState(
    initialExcludeMoveIndicators
  );
  const [gettingGames, setGettingGames] = useState(false);
  const openingFileInputRef = useRef<HTMLInputElement>(null);
  const statusRef = useRef<HTMLTextAreaElement>(null);

  function handleOpeningFileChange(event: any) {
    if (event.target.value.length > 0) {
      setOpeningFile(event.target.files[0]);
      setSelectedLichessStudy("");
      console.log(
        "Handling opening file by file change",
        event.target.files[0]
      );
      setOpeningStudyID("");
    } else {
      setOpeningFile("");
    }
  }
  function handleGameFileChange(event: any) {
    if (event.target.value.length > 0) {
      setGameFile(event.target.files[0]);
      setGamePGN("");

      setGameFileStudyID("");
    } else {
      setGameFile("");
    }
  }

  function processWithDots(duration: number) {
    let dots = "";
    let intervalId = setInterval(() => {
      dots += ".";
      if (dots.length > 9) {
        dots = "";
      }
      setProcessingDots("Processing" + dots);
    }, 500);

    setTimeout(() => {
      clearInterval(intervalId);
      console.log("Processing complete!");
    }, duration);
  }
  async function getOpeningText() {
    let openingText = "";
    if (openingFile !== "") {
      openingText = await openingFile.text();
    }
    if (selectedLichessStudy !== "") {
      openingText = await getPublicStudy(selectedLichessStudy.id);
    }
    if (openingStudyID !== "") {
      const result = await getPublicStudy(openingStudyID);
      console.log("Study by id", result);
      openingText = result;
      setOpeningStudyByID(result);
      setSelectedLichessStudy("");
      setOpeningFile("");
      if (openingFileInputRef.current) {
        openingFileInputRef.current.value = "";
      }
    }
    return openingText;
  }

  async function coverageSuspended() {
    statusRef.current?.focus();
    setStatus(
      "Sorry but coverage check functionality is undergoing maintenance.  Estimated return is 11/03/24 01:00 p.m. pst"
    );
    setResults(
      "Sorry but coverage check functionality is undergoing maintenance.  Estimated return is 11/03/24 01:00 p.m. pst"
    );
  }
  async function handleCoverageCheck(color: string) {
    try {
      setResults("");
      processWithDots(30000);
      setProcessing(true);
      statusRef.current?.focus();
      const statsFromDB: any = await getDBStats(ratings, speeds);
      const currentSpeeds = getExplorerSettings(ratings, speeds).speeds;
      const currentRatings = getExplorerSettings(ratings, speeds).ratings;
      if (
        openingStudyID !== "" ||
        openingFile !== "" ||
        selectedLichessStudy !== ""
      ) {
        let openingText = await getOpeningText();

        let openingBook = await getBook(
          openingText,
          selectedColor,
          excludeMoveIndicators
        );

        //Determine how many fens need to be processed
        let fens_to_process_count = 0;
        for (const fen of Object.keys(openingBook)) {
          let fenColor = getColorFromFullFen(fen);
          if (fenColor !== selectedColor) {
            fens_to_process_count += 1;
          }
        }

        //Start going through all the fens
        //Logic:
        //If it is opponents turn then:
        //For this position for each of the opponent's possible moves
        //as listed by Lichess
        let fens_processed = 0;
        for await (const fen of Object.keys(openingBook)) {
          const fenColor = getColorFromFullFen(fen);
          if (fenColor !== color) {
            fens_processed += 1;
            let games: any = {};
            //ToDo: Are we properly considering bad move flag?
            if (fen in statsFromDB) {
              games = statsFromDB[fen].games;
            } else {
              games = await getExplorerGames(
                getExplorerSettings(ratings, speeds).speedsArray,
                getExplorerSettings(ratings, speeds).ratingsArray,
                fen
              );
              const newStats: any = {};
              newStats[fen] = {
                speeds: currentSpeeds,
                ratings: currentRatings,
                games: games,
              };
              await updateDBStats(newStats);
              setResults(
                "Getting data from Lichess for:" +
                  fens_processed +
                  " of " +
                  fens_to_process_count +
                  " fens"
              );
              await new Promise((resolve) => setTimeout(resolve, 1200));
            }

            const totalGamesAtThisFen = getTotalGames(games);
            const explorer_moves = games[
              "moves" as keyof typeof games
            ] as unknown as [];
            //Get all opposition moves the book considers for this position
            const openingBook_moves =
              openingBook[fen as keyof typeof openingBook]["moves"];
            for (const san of Object.keys(openingBook_moves)) {
              //Is this move by opposition in lichess
              const findResult = explorer_moves.find(
                (next) => next["san"] === san
              );
              if (findResult) {
                let board = new Chess(fen);
                board.move(san);
                let fen_after_move = changeCountToFakeCount(board.fen());
                //We have opponents move from this fen that is in the book and this move is in Lichess
                //Now after that move by opponent does the book have a response?
                if (Object.keys(openingBook).includes(fen_after_move)) {
                  const number_of_games_opponent_makes_this_move =
                    getTotalGames(findResult);
                  let this_newBook_move: any = openingBook_moves[san];
                  this_newBook_move["number_of_games"] =
                    number_of_games_opponent_makes_this_move;
                }
              }
            }
            let theFen: any = openingBook[fen as keyof typeof openingBook];
            theFen["total_games"] = totalGamesAtThisFen;
          }
        }
        let coverageString = await generateCoverageString(
          openingBook,
          selectedColor
        );
        setResults(coverageString);
        setProcessing(false);
        await new Promise((resolve) => setTimeout(resolve, 800));
        setStatus("Done");
      } else {
        setResults("No opening entered, please enter an opening above");
        setStatus("No opening entered, please enter an opening above");
        setProcessing(false);
      }
    } catch (e) {
      setProcessing(false);
      setResults("Sorry...something went wrong, we'll look into it:" + e);
      console.log("Error", e);
    }
  }

  async function handleDoConsistencyCheck(color: string) {
    try {
      setStatus("Processing");
      setResults("");
      statusRef.current?.focus();
      if (
        openingStudyID !== "" ||
        openingFile !== "" ||
        selectedLichessStudy !== ""
      ) {
        let openingText = "";
        let nameOfOpeningFile = "";
        if (openingFile !== "") {
          openingText = await openingFile.text();
          nameOfOpeningFile = openingFile.name;
        }
        if (selectedLichessStudy !== "") {
          openingText = await getPublicStudy(selectedLichessStudy.id);
          nameOfOpeningFile = selectedLichessStudy.name;
        }
        if (openingStudyID !== "") {
          const result = await getPublicStudy(openingStudyID);
          console.log("Study by id", result);
          openingText = result;
          nameOfOpeningFile = result.split("\n")[0];
          setOpeningStudyByID(result);
          setSelectedLichessStudy("");
          setOpeningFile("");
          if (openingFileInputRef.current) {
            openingFileInputRef.current.value = "";
          }
        }
        processWithDots(10000);
        setProcessing(true);
        const url =
          process.env.NODE_ENV === "production"
            ? getServerURL() + "/api/consistency_check"
            : "http://localhost:5001/api/consistency_check";
        const formData = new FormData();
        formData.append("opening_text", openingText);
        formData.append("color", selectedColor);
        for (const [k, v] of Object.entries(excludeMoveIndicators)) {
          if (v.value) {
            formData.append("excludeSymbols[]", v.symbol);
          }
        }
        const config = {
          headers: {
            "content-type": "multipart/form-data",
          },
        };
        const response = await axios.post(url, formData, config);
        setProcessing(false);
        let results = response.data["results"];
        if (results === "") {
          results = "No inconsistencies found";
        }
        results =
          "Consistency Results for: " + nameOfOpeningFile + "\n" + results;
        setStatus("Done");
        setResults(results);
      } else {
        setResults("Please choose an opening file above");
      }
    } catch (e) {
      setProcessing(false);
      setResults("Sorry...something went wrong, we'll look into it:" + e);
      console.log(e);
    }
  }
  async function handleCompareGame() {
    try {
      setStatus("Processing..");
      setResults("");
      statusRef.current?.focus();
      console.log(openingFile);
      console.log("env", process.env.NODE_ENV);
      if (
        (openingFile !== "" ||
          selectedLichessStudy !== "" ||
          openingStudyID !== "") &&
        (gameFile !== "" ||
          gamePGN !== "" ||
          gameFileStudyID !== "" ||
          selectedGameIndex !== "")
      ) {
        processWithDots(10000);
        setProcessing(true);
        let gameText = "";
        let nameOfGameFile = "";
        if (gameFile !== "") {
          gameText = await gameFile.text();
          nameOfGameFile = gameFile.name;
        }
        if (gamePGN !== "") {
          gameText = gamePGN;
          nameOfGameFile = "Used Pasted PGN";
        }
        if (selectedGameIndex !== "") {
          const game = retrievedGames[selectedGameIndex];
          gameText = makePgn(game);
          nameOfGameFile =
            game.headers.get("White") +
            " vs. " +
            game.headers.get("Black") +
            " " +
            game.headers.get("Date")?.slice(5) +
            " " +
            game.headers.get("UTCTime")?.slice(0, -3);
        }
        if (gameFileStudyID !== "") {
          const gameFile = await getPublicStudy(gameFileStudyID);
          console.log("Game File by Study by id", gameFile);

          setGameFile("");
          setGamePGN("");
          gameText = gameFile;
          nameOfGameFile = "Lichess Study with ID:" + gameFileStudyID;
        }

        let openingText = "";

        let nameOfOpeningFile = "";

        if (openingFile !== "") {
          openingText = await openingFile.text();
          nameOfOpeningFile = openingFile.name;
        }
        if (selectedLichessStudy !== "") {
          openingText = await getPublicStudy(selectedLichessStudy.id);
          nameOfOpeningFile = selectedLichessStudy.name;
        }
        if (openingStudyID !== "") {
          const studyByID = await getPublicStudy(openingStudyID);
          console.log("Study by id", studyByID);
          setOpeningStudyByID(studyByID);
          setSelectedLichessStudy("");
          setOpeningFile("");
          if (openingFileInputRef.current) {
            openingFileInputRef.current.value = "";
          }
          openingText = openingStudyByID;
          nameOfOpeningFile = "Lichess study with ID:" + openingStudyID;
        }

        const url =
          process.env.NODE_ENV === "production"
            ? getServerURL() + "/api/check_opening"
            : "http://localhost:5001/api/check_opening";
        const formData = new FormData();
        formData.append("opening_text", openingText);
        formData.append("game_text", gameText);
        formData.append("color", selectedColor);
        for (const [k, v] of Object.entries(excludeMoveIndicators)) {
          if (v.value) {
            formData.append("excludeSymbols[]", v.symbol);
          }
        }
        formData.forEach((next) => console.log("next form data", next));
        const config = {
          withCredentials: true,
          headers: {
            "content-type": "multipart/form-data",
          },
        };
        console.log("posting");
        const response = await axios.post(url, formData, config);
        console.log("back");

        setProcessing(false);
        console.log("Resp", response);
        let results = response.data;
        results =
          "Opening: " +
          nameOfOpeningFile +
          "\n" +
          "Game(s):" +
          nameOfGameFile +
          "\n" +
          results;
        setResults(results);
      } else {
        let message = "";

        if (
          openingFile === "" &&
          selectedLichessStudy === "" &&
          openingStudyID === ""
        ) {
          message = "Please select an opening file\n";
        }
        if (gameFile === "" && gamePGN === "" && gameFileStudyID === "") {
          message = message + "Please select a game file";
        }
        setResults(message);
      }
    } catch (e) {
      setProcessing(false);
      setResults("Sorry...something went wrong, we'll look into it:" + e);
      console.log("Errozzzzzzr", e);
    }
  }
  const speedsHTML: any[] = [];
  const speedKeys = Object.keys(speeds);
  speedKeys.forEach((key) => {
    let speed: keyof typeof speeds = key as keyof typeof speeds;
    speedsHTML.push(
      <label css={speedsCss} key={key}>
        {speed}
        <input
          css={speedsCss}
          type="checkbox"
          checked={speeds[speed]}
          onChange={() => {
            setSpeeds({
              ...speeds,
              [speed]: !speeds[speed],
            });
          }}
        ></input>
      </label>
    );
  });
  const ratingsHTML: any[] = [];
  const ratingKeys = Object.keys(ratings);
  ratingKeys.forEach((key) => {
    let rating: keyof typeof ratings = key as keyof typeof ratings;
    ratingsHTML.push(
      <label css={ratingsCss} key={key}>
        {rating}
        <input
          css={ratingsCss}
          type="checkbox"
          checked={ratings[rating]}
          onChange={() => {
            setRatings({
              ...ratings,
              [rating]: !ratings[rating],
            });
          }}
        ></input>
      </label>
    );
  });
  const excludeHTML: any[] = [];
  const myKeys = Object.keys(excludeMoveIndicators);
  myKeys.forEach((next) => {
    let nextIndicator: keyof typeof excludeMoveIndicators =
      next as keyof typeof excludeMoveIndicators;
    excludeHTML.push(
      <label key={nextIndicator}>
        {excludeMoveIndicators[nextIndicator]["symbol"]}
        <input
          type="checkbox"
          checked={excludeMoveIndicators[nextIndicator]["value"]}
          onChange={() => {
            setExcludeMoveIndicators({
              ...excludeMoveIndicators,
              [nextIndicator]: {
                symbol: excludeMoveIndicators[nextIndicator].symbol,
                value: !excludeMoveIndicators[nextIndicator].value,
              },
            });
          }}
        ></input>
      </label>
    );
  });
  return (
    <div css={containerCss}>
      <div>Select Opening:</div>
      <div css={settingsGroupCss}>
        <div css={optionCss}>
          <div css={optionHeadingCss}>Upload pgn file:</div>
          <input
            id="choose_opening"
            ref={openingFileInputRef}
            type="file"
            name="opening_file"
            onChange={handleOpeningFileChange}
            data-testid={"opening_file_chooser"}
          />
        </div>
        <div css={optionCss}>
          <div css={optionHeadingCss}>Or Get List of Studies:</div>
          <div>
            <label>Lichess Username:</label>
            <input
              value={lichessOpeningUsername}
              onChange={(e) => setLichessOpeningUsername(e.target.value)}
              data-testid={"opening_study_list_username"}
            ></input>
            <button
              onClick={async () => {
                const studies = await getPublicStudies(lichessOpeningUsername);
                setlichessStudies(studies);
              }}
              data-testid={"opening_get_study_list_button"}
            >
              Get Studies
            </button>
            <ul css={studyListCss} test-dataid={"study_list"}>
              <li
                onClick={() => {
                  setSelectedLichessStudy("");
                  if (openingFileInputRef.current) {
                    openingFileInputRef.current.value = "";
                  }
                }}
                style={{
                  backgroundColor:
                    selectedLichessStudy === "" ? "LightGray" : "white",
                }}
              >
                None...using file upload or study id
              </li>
              {lichessStudies.map((item: any) => (
                <li
                  key={item.id}
                  style={{
                    backgroundColor:
                      selectedLichessStudy === item ? "LightGray" : "white",
                  }}
                  onClick={() => {
                    setSelectedLichessStudy(item);
                    setOpeningFile("");
                    setOpeningStudyByID("");
                    if (openingFileInputRef.current) {
                      openingFileInputRef.current.value = "";
                    }
                  }}
                >
                  {item.name}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div css={optionCss}>
          <div css={optionHeadingCss}>Or Get Lichess Study by ID:</div>
          <div>
            <label>Lichess Study ID:</label>
            <input
              value={openingStudyID}
              onChange={(e) => {
                setOpeningStudyID(e.target.value);
                if (openingFileInputRef.current) {
                  openingFileInputRef.current.value = "";
                }
              }}
            ></input>
            <div>
              <div>{openingStudyByID.split("\n")[0]}</div>
            </div>
          </div>
        </div>
        <div>
          <div css={optionHeadingCss}>Properties:</div>
          <div>Color:</div>
          <label>
            <input
              type="radio"
              value="white"
              checked={selectedColor === "white"}
              onChange={(e) => {
                setSelectedColor(e.target.value);
              }}
            ></input>
            white
          </label>
          <label>
            <input
              type="radio"
              value="black"
              checked={selectedColor === "black"}
              onChange={(e) => {
                setSelectedColor(e.target.value);
              }}
            ></input>
            black
          </label>
          <div>Exclude Move Indicators:(none or multiple):</div>
          <div css={indicatorOptionsCss}>{excludeHTML}</div>
          <button
            onClick={async () => {
              await handleDoConsistencyCheck(selectedColor);
            }}
            data-testid={"do_consistency_check_button"}
          >
            Do Consistency Check
          </button>
        </div>
      </div>
      <div>Opening Coverage:</div>
      <div css={settingsGroupCss}>
        <div>
          Game Filters:
          <div css={exlporerSettingsCss}>
            <div>{speedsHTML}</div>
          </div>
          <div css={exlporerSettingsCss}>
            <div>{ratingsHTML}</div>
          </div>
        </div>
        <div>
          <button
            onClick={async () => {
              await handleCoverageCheck(selectedColor);
            }}
          >
            Do Coverage Check
          </button>
        </div>
      </div>
      <div>Game(s)</div>

      <div css={openingContainerCss}>
        <div css={optionCss}>
          <div css={optionHeadingCss}>Upload file:</div>
          <input
            ref={gameFileInputRef}
            id="choose_game"
            type="file"
            name="game_file"
            onChange={handleGameFileChange}
          />
        </div>
        <div css={optionCss}>
          <div css={optionHeadingCss}>Or Paste pgn:</div>
          <textarea
            value={gamePGN}
            onChange={(e) => setGamePGN(e.target.value)}
            css={gamePGNCss}
          ></textarea>
        </div>

        <div css={optionCss}>
          <div css={optionHeadingCss}>Or Get List of Recent Games:</div>
          <div>
            <label>Username:</label>
            <input
              value={gameUsername}
              onChange={(e) => setGameUsername(e.target.value)}
            ></input>
            <div>
              <button
                onClick={async () => {
                  setGettingGames(true);
                  const games = await getPublicLichessGames(gameUsername);
                  if (games.length === 0) {
                    setResults(
                      "No games found..either no games or no such user"
                    );
                  }
                  setGettingGames(false);
                  setRetrievedGames(games);
                }}
              >
                Get Lichess
              </button>
              <button
                onClick={async () => {
                  setGettingGames(true);
                  const games = await getChesssComGames(gameUsername);
                  if (games.length === 0) {
                    setResults(
                      "No games found..either no games or no such user"
                    );
                  }
                  setRetrievedGames(games);
                  setGettingGames(false);
                }}
              >
                Get Chess.com
              </button>
            </div>
            <div>Select Game:</div>
            {gettingGames ? (
              <div css={studyListCss}>Retrieving Games</div>
            ) : (
              <ul css={studyListCss}>
                <li
                  onClick={() => {
                    setSelectedGameIndex("");
                    if (openingFileInputRef.current) {
                      openingFileInputRef.current.value = "";
                    }
                  }}
                  style={{
                    backgroundColor:
                      selectedGameIndex === "" ? "LightGray" : "white",
                  }}
                >
                  None...using file upload or study id
                </li>
                {retrievedGames.map((item: any, index) => (
                  <li
                    key={index}
                    style={{
                      backgroundColor:
                        selectedGameIndex === index ? "LightGray" : "white",
                    }}
                    onClick={() => {
                      setSelectedGameIndex(index);
                      setGameFile("");
                      setGameFileStudyID("");
                      if (gameFileInputRef.current) {
                        gameFileInputRef.current.value = "";
                      }
                    }}
                  >
                    {item.headers.get("White") +
                      " vs. " +
                      item.headers.get("Black") +
                      " " +
                      item.headers.get("Date")?.slice(5) +
                      " " +
                      item.headers.get("UTCTime")?.slice(0, -3)}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
        <div css={optionCss}>
          <div css={optionHeadingCss}>
            Or Get Lichess Study With Game(s) by ID:
          </div>
          <div>
            <label>Lichess Study ID:</label>
            <input
              value={gameFileStudyID}
              onChange={(e) => {
                setGameFileStudyID(e.target.value);
                if (gameFileInputRef.current != null) {
                  gameFileInputRef.current.value = "";
                }
              }}
            ></input>
          </div>
        </div>
        <button onClick={() => handleCompareGame()}>
          Compare game(s) to opening
        </button>
      </div>
      <div></div>
      <div>Status:</div>
      <textarea
        ref={statusRef}
        css={statusCss}
        value={processing ? processingDots : status}
        readOnly
      ></textarea>
      <div>Results:</div>
      <textarea
        css={resultsCss}
        value={results}
        readOnly
        data-testid={"results"}
      ></textarea>
    </div>
  );
};

const indicatorOptionsCss = css`
  display: flex;
  justify-content: space-around;
`;

const exlporerSettingsCss = css`
  display: flex;
  justify-content: space-between;
`;
const studyListCss = css`
  list-style-type: none;
  font-family: Inter, monospace, "Courier", Courier, monospace, sans-serif;
  font-size: 10px;
  text-align: left;
  border: solid 1px;
  max-height: 75px;
  overflow: auto;
  margin: 5px;
  padding: 0;
`;

const speedsCss = css`
  list-style-type: none;
  font-family: Inter, monospace, "Courier", Courier, monospace, sans-serif;
  font-size: 10px;
  text-align: right;
  width: 16px;
  padding: 0;
  margin: 0;
  height: 8px;
`;
const ratingsCss = css`
  list-style-type: none;
  font-family: Inter, monospace, "Courier", Courier, monospace, sans-serif;
  font-size: 10px;
  text-align: right;
  width: 16px;
  padding: 0;
  margin: 0;
  height: 8px;
`;

const settingsGroupCss = css`
  border-radius: 2%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  border: solid;
  max-width: 400px;
  padding: 5px;
  margin-bottom: 25px;
`;

const openingContainerCss = css`
  border-radius: 2%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  border: solid;
  max-width: 400px;
  padding: 5px;
  margin-bottom: 25px;
`;

const containerCss = css`
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin: 5px;
`;

const gamePGNCss = css`
  min-width: 270px;
`;

const statusCss = css`
  font-size: 12px;
  display: flex;
  flex-direction: column;
  border: solid;
  height: 14px;
  min-height: 8px;
  max-width: 600px;
  margin-bottom: 5px;
`;
const resultsCss = css`
  display: flex;
  flex-direction: column;
  gap: 5px;
  border: solid;
  min-height: 400px;
  padding: 5px;
  margin-bottom: 25px;
`;

const optionCss = css`
  display: flex;
  flex-direction: column;
  max-width: 800px;
  margin-bottom: 10px;
`;

const optionHeadingCss = css`
  text-align: center;
  display: flex;
  margin-bottom: 5px;
  align-items: center;
  &:before,
  :after {
    content: " ";
    flex: 1 0 0%;
    height: 1.5px;
    background: darkgray;
    margin: 0 0.5rem 0 0;
  }
  &:after {
    margin: 0 0 0 0.5rem;
  }
`;
