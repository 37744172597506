/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Fragment, useState, useRef } from "react";
import { Link } from "@reach/router";

import { colorsUse, alternateBorders } from "../css/css";
import { Instructions } from "../game/instructions";
import React from "react";

export function AppNavBar() {
  // const [menuOpen, setMenuOpen] = useState(false);

  // const deviceSize = useMedia();
  const [showInstructions, setShowInstructions] = useState(false);
  const instructionsRef = useRef<HTMLDialogElement>(null);

  const openInstructions = () => {
    setShowInstructions(true);
    if (instructionsRef.current) {
      instructionsRef.current.showModal();
    }
  };

  const closeInstructions = () => {
    setShowInstructions(false);
    if (instructionsRef.current) {
      instructionsRef.current.close();
    }
  };

  return (
    <Fragment>
      <nav css={appNavBarContainerCss} data-testid="nav_bar">
        <Link to="/home" css={logoContainerCss}>
          <div css={logoTextCss}>Rev Chess</div>
          <div css={menuItemsContainerStyling}>
            <div
              onClick={() => {
                openInstructions();
              }}
              css={navBarMenuButtonStyling}
            >
              Help
            </div>
            <dialog ref={instructionsRef}>
              <Instructions closeFunction={closeInstructions}></Instructions>
            </dialog>
          </div>
        </Link>
      </nav>
    </Fragment>
  );
}
const logoContainerCss = css`
  display: flex;
  align-items: top;
  text-decoration: none;
`;

const logoTextCss = css`
  margin-left: 5px;
  color: black;
  font-family: Inter, monospace, Arial, Helvetica, sans-serif;
  font-style: italic;
  font-weight: 400;
  text-decoration: underline;
`;

// const hideableMenuContainerCss = css`
//   display: flex;
//   flex-direction: column;
//   align-items: flex-start;
//   border: 1px solid ${colors.DarkGrey};
//   ${alternateBorders()};
// `;

const appNavBarContainerCss = css`
  position: sticky;
  top: 0;
  left: 0;
  margin: auto;
  margin-top: 0px;
  display: flex;
  height: 20px;
  background-color: white;
  justify-content: left;
  align-items: top;
  ${alternateBorders()}
`;

const navBarMenuButtonStyling = css`
  color: ${colorsUse.defaultLink};
  margin-left: 10px;
  margin-right: 10px;
  height: 20px;
`;

const menuItemsContainerStyling = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  align-items: flex-start;
  margin-right: 10px;
  margin-left: 10px;
  text-decoration: underline;
  ${alternateBorders()};
`;
