import { move } from "formik";
import {
  percentFormatter,
  getColorFromFullFen,
  getMoveNumber,
  fenWithoutMoveCounts,
} from "../utilities/general";
export async function generateCoverageString(
  bookWithStats: any,
  selectedColor: string
) {
  const fens = Object.keys(bookWithStats);
  fens.sort((a, b) => {
    const aColor = getColorFromFullFen(a);
    const aMoveNumber: any = parseInt(bookWithStats[a]["lowest_move_number"]);
    const bMoveNumber: any = parseInt(bookWithStats[b]["lowest_move_number"]);
    if (aMoveNumber === bMoveNumber)
      if (aColor === "white") {
        return -1;
      } else {
        return 1;
      }
    if (aMoveNumber < bMoveNumber) {
      return -1;
    } else {
      return 1;
    }
  });
  let coverageString = "";
  let coverageObject: any = new Object();
  coverageObject["*"] = "some fen";
  coverageObject["Games"] = 10000;

  fens.forEach((fen) => {
    let fenColor = getColorFromFullFen(fen);
    let pathsToFen: any = {};
    if (fenColor !== selectedColor) {
      let pgnString = "";
      let moveString = "";
      let fenString = "";
      const moves = Object.keys(bookWithStats[fen]["moves"]);
      let count_for_all_moves = 0;
      let fen_game_count = bookWithStats[fen]["total_games"];

      moveString = "";
      let movesHeader = "\n";
      let moveStringArray: any = [];
      let noResponseStringArray: any = [];
      moves.forEach((move: any) => {
        let moveChapters: any = [];
        bookWithStats[fen]["moves"][move]["chapters"].forEach(
          (chapter: any) => {
            moveChapters.push(chapter["event"]);
            if (chapter["pgn"] in pathsToFen) {
              if (!pathsToFen[chapter["pgn"]].includes(chapter["event"]))
                pathsToFen[chapter["pgn"]].push(chapter["event"]);
            } else {
              pathsToFen[chapter["pgn"]] = [chapter["event"]];
            }
          }
        );

        let count_this_move =
          bookWithStats[fen]["moves"][move]["number_of_games"];
        if ("number_of_games" in bookWithStats[fen]["moves"][move]) {
          count_this_move = count_this_move ? count_this_move : 0;
          count_for_all_moves = count_for_all_moves + count_this_move;
          let percentThisMoveString =
            count_this_move / fen_game_count
              ? percentFormatter.format(count_this_move / fen_game_count)
              : " 0%";
          let percentThisMove =
            count_this_move / fen_game_count
              ? count_this_move / fen_game_count
              : 0;
          moveStringArray.push({
            move: move,
            percent: percentThisMove,
            percentString: percentThisMoveString,
            moveChapters: moveChapters,
          });
          moveStringArray.sort((a: any, b: any) => {
            if (a.percent - b.percent > 0) {
              return -1;
            } else {
              return 1;
            }
          });
        } else {
          noResponseStringArray.push(move);
        }
      });
      moveStringArray.forEach((next: any) => {
        moveString += next.move.padEnd(5, " ") + next.percentString + " ";
        let isFirstChapter = true;
        next.moveChapters.forEach((chapter: any) => {
          if (isFirstChapter) {
            moveString += " " + chapter;
          } else {
            moveString += " & " + chapter;
          }
          isFirstChapter = false;
        });
        moveString += "\n";
      });
      if (noResponseStringArray.length > 0) {
        moveString +=
          "Opponent Moves In Book but no response in book (end of line in book)\n";
        noResponseStringArray.forEach((next: any) => {
          moveString += next + "\n";
        });
      }
      let percent_all_moves =
        count_for_all_moves / fen_game_count
          ? percentFormatter.format(count_for_all_moves / fen_game_count)
          : "0%";
      fenString +=
        "\nTotal Coverage:" +
        percent_all_moves +
        "\nGames:" +
        fen_game_count +
        "\nFEN:" +
        fenWithoutMoveCounts(fen);
      moveString = movesHeader + moveString;
      pgnString = "";
      for (let pgn in pathsToFen) {
        pgnString += "\n" + pgn.trimEnd();
      }
      pgnString += fenString;
      coverageString += pgnString + moveString + "\n";
    }
  });
  return coverageString;
}
