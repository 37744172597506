import axios from "axios";
import { getDBStats } from "../database/lichess_stats";
import { getServerURL } from "../utilities/general";
import { getExplorerSettings } from "./get_explorer_settings";

const initialExcludeMoveIndicators = {
  blunder: { value: false, symbol: "??" },
  mistake: { value: false, symbol: "?" },
  speculative: { value: false, symbol: "!?" },
  dubious: { value: false, symbol: "?!" },
};

export async function getBook(
  openingText: string,
  selectedColor: string,
  excludeMoveIndicators: typeof initialExcludeMoveIndicators
) {
  const url =
    process.env.NODE_ENV === "production"
      ? getServerURL() + "/api/create_opening_book"
      : "http://localhost:5001/api/create_opening_book";
  const formData = new FormData();
  formData.append("opening_text", openingText);
  formData.append("color", selectedColor);
  for (const [k, v] of Object.entries(excludeMoveIndicators)) {
    if (v.value) {
      formData.append("excludeSymbols[]", v.symbol);
    }
  }
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  const response = await axios.post(url, formData, config);
  let newBook = JSON.parse(response.data["book"]);
  return newBook;
}
