import { getExplorerSettings } from "../game/get_explorer_settings";
import { getServerURL } from "../utilities/general";
import axios from "axios";
export async function getDBStats(ratings: {}, speeds: {}) {
  const url =
    process.env.NODE_ENV === "production"
      ? getServerURL() +
        "/api/get_stats?speeds=" +
        getExplorerSettings(ratings, speeds).speeds +
        "&ratings=" +
        getExplorerSettings(ratings, speeds).ratings
      : "http://localhost:5001/api/get_stats?speeds=" +
        getExplorerSettings(ratings, speeds).speeds +
        "&ratings=" +
        getExplorerSettings(ratings, speeds).ratings;
  const response = await axios.get(url);
  const statsFromDB: any = {};
  const data = response.data;
  data.forEach((row: any) => {
    statsFromDB[row["fen"]] = {
      speeds: row["speeds"],
      ratings: row["ratings"],
      games: {
        black: row["black"],
        white: row["white"],
        draws: row["draws"],
        moves: row["moves"],
      },
    };
  });
  return statsFromDB;
}

export async function updateDBStats(newStats: any) {
  const url =
    process.env.NODE_ENV === "production"
      ? getServerURL() + "/api/update_stats"
      : "http://localhost:5001/api/update_stats";
  const formData = new FormData();

  const dataForFormArray: any = [];
  for (const fen in newStats) {
    const dataForForm = {
      fen: fen,
      ratings: newStats[fen].ratings,
      speeds: newStats[fen].speeds,
      white: newStats[fen].games.white,
      black: newStats[fen].games.black,
      draws: newStats[fen].games.draws,
      moves: JSON.stringify(newStats[fen].games.moves),
    };
    dataForFormArray.push(dataForForm);
  }
  formData.append("stats", JSON.stringify(dataForFormArray));
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  const response = await axios.post(url, formData, config);
}
