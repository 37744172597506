/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { AppNavBar } from "./navigation/app_nav_bar";
import { alternateBorders } from "./css/css";
import React from "react";
import { useEffect } from "react";
import { ReviewOpening } from "./game/review_opening";
import { Instructions } from "./game/instructions";
import { useUser } from "./user/user";
//import GitInfo from "react-git-info/macro";

export default function App() {
  // const user = useUser();

  // useBeforeunload((event) => {
  //   if (user.unsavedChanges) {
  //     event.preventDefault();
  //   }
  // });

  // useEffect(() => {
  //   const checkLogIn = async () => {
  //     await user.checkIfLoggedIn();
  //   };
  //   checkLogIn();
  // }, []); // eslint-disable-line react-hooks/exhaustive-deps

  //const gitInfo = GitInfo();
  return (
    <div css={appContainerCss}>
      <AppNavBar />
      <ReviewOpening></ReviewOpening>
      {/* //<div>{gitInfo.tags}</div> */}
    </div>
  );
}
const appContainerCss = css`
  ${alternateBorders()}
`;
